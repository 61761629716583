@import '../../../media-queries.scss';
@import '../_variables.scss';

.bottom-navbar * {
  pointer-events: auto;
}

.bottom-navbar {
  pointer-events: none;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: $bottom-navvar-height;
  z-index: 3;

  // background-color: rgba(0, 0, 0, 0.2);
  // -webkit-backdrop-filter: blur(33px);
  // backdrop-filter: blur(33px);
  background-color: black;

  padding: 10px; // 10px 25px 10px;

  display: flex;
  flex-direction: row;
  gap: 14%;
  align-items: center;
  justify-content: center;

  .navbar-items {
    width: 100%;
    max-width: 600px;

    display: flex;
    flex-direction: row;
    gap: 13%;
    align-items: center;
    justify-content: center;

    padding: 10px;

    .navbar-item {
      font-size: 10px;
      font-weight: 600;
      line-height: 13px;
      text-decoration: none;
      text-align: center;
      color: white;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .navbar-item-icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 26px;
        height: 26px;
        margin: auto;
      }
      .navbar-item-label {
        height: fit-content;
      }
    }
  }
}

@media (max-width: 699px) {
  .bottom-navbar {
    padding: 10px 10px 25px 10px;

    .navbar-items {
      .navbar-item {
        .navbar-item-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
