@import '../../../media-queries.scss';

.home-layout {
  width: 100%;
  height: 100%;
}

.home-layout-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.home-layout-title-container {
  width: 100%;
}

.home-layout-title {
  font-size: 63px;
  line-height: 84px;
  color: #7848ff;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.home-layout-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 21px;
  padding-left: 3px;
  color: white;
  text-align: center;
  width: 100%;
}

@include respond-below(md) {
  .home-layout-header {
    margin-bottom: 1em;
  }

  .home-layout-title {
    font-size: 50px;
    line-height: 55px;
  }

  .home-layout-subtitle {
    font-size: 28px;
    line-height: 30px;
  }
}

@include respond-below(sm) {
  .home-layout-header {
    margin-bottom: 0;
  }

  .home-layout-title {
    font-size: 35px;
    line-height: 37px;
  }

  .home-layout-subtitle {
    font-size: 19px;
    line-height: 19px;
    width: 215px;
    text-align: center;
    margin: 0 auto;
  }
}

@keyframes home-layout-subtitle-anim {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

// ====================================

.home-layout-columns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.home-layout-column {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: transparent;
  color: #7848ff;
  font-family: 'Space Mono';
  font-size: 16px;
  min-width: 300px;
}

@include respond-below(md) {
  .home-layout-columns {
    display: block;
    margin-bottom: 50px;
  }

  .home-layout-column {
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
  }

  // -------------------
  .home-form-box-input {
    flex-direction: column-reverse;
    margin-bottom: 0px;
  }

  .home-form-column-input {
    overflow: hidden;
    width: 100%;

    &.left {
      width: 100%;
    }

    &.right {
      width: 100%;
      height: 35px;
      margin-bottom: -18px;
    }
  }

  // -------------------

  .home-form-column-connect {
    overflow: hidden;
    width: 100%;

    &.left {
      width: 30%;
      min-width: 30%;
    }

    &.right {
      width: 70%;
    }
  }
}
