.community-guidelines-page {
  max-width: 100vw;
  min-width: 100vw;
  background: #000;
  padding-bottom: 10vh;
  font-size: min(calc(0.5px + 0.05vh), 0.15vw);

  section {
    margin: auto;
    width: 100vw;
    padding: 2vh 4vh 0;
    cursor: default;
    font-size: 30em;
    max-width: 100svh;
  }

  h1 {
    margin-bottom: 0.5em;
    font-family: 'Space Mono', sans-serif;
    font-size: 2.7em;
    font-weight: 900;
    letter-spacing: 0.04em;
    color: #80ffc2;
    line-height: 100%;
  }

  h2 {
    font-family: 'Space Mono';
    font-size: 1.5em;
    font-weight: bold;
    color: #7848ff;
    margin: 1em 0 0.5em;
  }

  p,
  a,
  li {
    color: #c9bfd8;
    font-family: 'Poppins', sans-serif;
  }

  a {
    font-weight: 900;
    text-decoration: underline;
    transition: color 0.4s;
  }

  a:hover {
    color: #fff;
  }
}
