.notifications {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    display: flex;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid white;
    align-items: center;
    padding: 12px;
    flex-direction: row;
    justify-content: flex-end;
  }

  h1 {
    color: white;
  }

  .eth {
    border: white solid 1px;
    border-radius: 15px;
    padding: 4px 8px;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nnav {
    margin-top: 12px;
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 20px;
    justify-content: space-around;

    .nav-item {
      cursor: pointer;
      color: white;
    }

    .selected {
      font-weight: bold;
    }
  }

  .toasts {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    // background-color: blue;
    flex: 1;
    overflow-y: scroll;

    overflow-y: auto;

    .toasty {
      display: flex;
      height: 70px;
      width: 100%;
      background-color: white;
      flex-direction: row;

      // profile images
      .pimgs {
        display: flex;
        flex-direction: row;
        flex: 0.4;
        align-items: center;
        justify-content: center;
        position: relative;

        max-width: 100px;

        .fimg {
          position: absolute;
          top: 13px;
          left: 11px;
          width: 34%;
        }

        img {
          width: 100%;
        }
      }

      .tinfo {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 4px 20px;
        font-size: 14px;
        justify-content: center;

        p {
          margin-bottom: 2px !important;
        }

        .bought {
          color: green;
        }

        .sold {
          color: red;
        }

        .info {
          color: grey;
        }
      }
    }
  }
}
