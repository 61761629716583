.fake {
  width: 100%;
  height: 100%;
  display: flex;

  .page {
    display: flex;
    flex: 1;
    // background: blue;
    align-items: center;
    justify-content: center;
  }

  .login-btn {
    width: 150px;
    height: 60px;
    border-radius: 15px;
  }
}
