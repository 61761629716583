@import '../../../media-queries.scss';
@import '../_variables.scss';

// ===================================================
// Carousel

.home-layout-body {
  height: 100%;
}

.carousel-root {
  height: 100%;
}

.carousel.carousel-slider {
  padding: 0;
  padding-top: 0;
  height: 100%;

  .control-dots {
    padding: 10px;
    bottom: 15px;
    width: 100%;

    margin: 0;
    padding: 0;
    .dot {
      width: 9px;
      height: 9px;
      margin: 0 5px;
    }
  }
}

// ===================================================
// Normal item

.item-container {
  position: relative;
  // background-color: #000;
  width: 100%;
  height: calc(100svh - $bottom-navvar-height); // account for bottom navbar

  overflow: hidden;

  .item-image {
    width: 100%;
    height: 100%;
    background-size: auto 100%; // cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
