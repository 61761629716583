// .mint-transition-section {
//   max-width: 600px;
//   margin: 0 auto;
//   height: 60vh;
//   position: relative;
//   .transitioning-section {
//     // height: 100%;

//     height: 100svh;
//     // background-color: red;
//     z-index: 9999;
//     overflow: hidden;
//   }
// }

// body {
//   overflow-y: auto;
// }

.mint-alert {
  font-size: 14px;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  padding: 10px 10px;
}

.mint-gradient-title {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(91.12deg, #6347f6 0.28%, #2b30f5 92.14%);
  background-position: 0% 0%;
  background-clip: text;
  // text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: 'Inter', sans-serif;
  font-weight: 700;
  letter-spacing: -0.04em;
}

.mint-btn-cta {
  background: linear-gradient(91.12deg, #6347f6 0.28%, #2b30f5 92.14%);
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding: 15px 30px;
  color: #f2f0ff;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: none;

  &:hover {
    text-decoration: none;
    color: #f2f0ff;
  }
  &:disabled {
    opacity: 0.5;
    color: #a7a7a7;
    cursor: default;

    &:hover {
      color: #a7a7a7;
    }
  }

  &.success {
    background: #00ffe0;
    color: black;

    &:disabled {
      opacity: 1;
      cursor: default;

      &:hover {
        color: black;
      }
    }
  }
}
