.need-account-modal-con {
  width: 100vw;
  height: 100svh;
  background-color: #000000dd;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-portal-close-button {
    right: -34px;
    top: -34px;
  }
}

.need-account-modal {
  position: relative;
  width: 600px;
  margin: 34px;

  h1 {
    margin-bottom: 20px;
  }

  .GradientTitle {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 29px;
    width: 80%;
    margin-bottom: 50px;
  }

  .mint-btn-cta {
    margin: 0 auto;
    display: block;
    max-width: 300px;
  }
}
